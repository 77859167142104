import React, { useContext, useEffect } from 'react'
import { addmoneySchema } from '../models'
import { useFormik } from 'formik'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import UserContext from '../context/UserContext'

const initialValues = {
  amount: 100,
}

const arr = [
  { amount: 100, title: '100 DOS Coins' },
  { amount: 250, title: '250 DOS Coins' },
  { amount: 500, title: '500 DOS Coins' },
  { amount: 1000, title: '1000 DOS Coins' },
  { amount: 1500, title: '1500 DOS Coins' },
  { amount: 2000, title: '2000 DOS Coins' },
  { amount: 2500, title: '2500 DOS Coins' },
  { amount: 5000, title: '5000 DOS Coins' },
]

export default function Dos() {
  const navigate = useNavigate()
  const context = useContext(UserContext)
  const { setLoader } = context
  const infoData = useSelector((state) => state.infoData)
  const { payment } = infoData

  useEffect(() => {
    document.title = 'Demon Official Store - DOZ Coins'
    // eslint-disable-next-line
  }, [])

  const { values, setValues, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues,
    validationSchema: addmoneySchema,
    onSubmit: async (values) => {
      try {
        if (payment.gateway === 'N.A') {
          setLoader(false)
          Swal.fire({
            icon: 'error',
            title: '',
            text: 'Gateway down, please try again leter!',
            footer: 'Alert by the Demon Official Store team',
            confirmButtonColor: '#008ad8',
          })
        } else {
          const url = `${process.env.REACT_APP_API_ROOT_URL}payment/${payment.gateway + payment.url}`
          setLoader(true)
          const response = await fetch(url, {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              authToken: localStorage.getItem('authToken'),
            },
            body: JSON.stringify(values),
          })
          const result = await response.json()
          if (result.success) {
            window.location.replace(result.data.paymentLink)
          } else {
            Swal.fire({
              icon: 'error',
              title: '',
              text: result.msg,
              footer: 'Alert by the Demon Official Store team',
              confirmButtonColor: '#008ad8',
            })
            setLoader(false)
          }
        }
      } catch (error) {
        setLoader(false)
        console.log(error)
        Swal.fire({
          icon: 'error',
          title: '',
          text: 'Something went wrong, please try again leter!',
          footer: 'Alert by the Demon Official Store team',
          confirmButtonColor: '#008ad8',
        })
      }
    },
  })

  return (
    <section className='wrapper product'>
      <div className='container'>
        <div className='row pt-32px g-md-5 g-3'>
          <div className='col-lg col-lg-340 p-0'>
            <div className='game-imgbox'>
              <img className='desktop-banner' src='images/banner/desktop/dos.png' alt='legends' />
              <img className='mobile-banner' src='images/banner/android/dos.png' alt='legends' />
            </div>

            <div className='game-content'>
              <div className='pt-3 d-flex'>
                <div className='game-smimgbox me-3'>
                  <img src='images/dos-wallet.png' alt='legends' className='game-smimg' />
                </div>
                <div>
                  <h6 className='game-heading'>Dos Coin</h6>

                  <div className='d-flex align-items-center pt-md-2'>
                    <svg className='icon me-2'>
                      <use href='#icon_energy'></use>
                    </svg>
                    <span>Instant Delivery</span>
                    <svg className='icon ms-3 me-2'>
                      <use href='#icon_secure'></use>
                    </svg>
                    <span>Secure Payments</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg content-wrapper'>
            <h5 className='details-heading'>Select a Package</h5>

            <div className='row row-cols-2 row-cols-md-3 g-md-3 g-2 pt-md-4 pt-2 pb-4'>
              {arr.map((element, index) => {
                return (
                  <div className='col' key={index}>
                    <label className='card-label' onClick={() => setValues({ ...values, amount: element.amount })}>
                      <input type='checkbox' checked={values.amount === element.amount} />
                      <div className='cl-content min-h132px'>
                        {element.badge && (
                          <div className='cl-badge'>
                            <small>{element.badge}</small>
                          </div>
                        )}
                        <div className='cl-title'>
                          <h4>{element.title}</h4>
                          <span>{element.description}</span>
                        </div>
                        <div className='cl-icon'>
                          <img src='images/dos.png' alt='diamond' />
                        </div>
                        <h6 className='cl-price'>₹{element.amount}</h6>
                      </div>
                    </label>
                  </div>
                )
              })}
            </div>

            <h5 className='details-heading mt-md-3 pb-md-4 pb-2'>Choose Payment Method</h5>

            <div className='row pb-md-4 pb-2 g-md-4 g-md-3 g-2'>
              <div className='col-12 mb-md-0 mb-3'>
                <label className='card-label'>
                  <input type='checkbox' checked />
                  <div className='cl-content payment-card'>
                    <div className='w-100 d-flex  align-items-center'>
                      <div className='left-upi'>
                        <div className='img-box'>
                          <img src='images/upi.svg' alt='upi' />
                        </div>
                        <h3 className='coin-name'>UPI</h3>
                      </div>
                      <div className='right-coin ms-auto me-1'>
                        <div className='d-flex align-items-center'>
                          <div className='img-upibox'>
                            <img src='images/gpay.svg' alt='gpay' />
                          </div>
                          <div className='img-upibox'>
                            <img src='images/phonepe.svg' alt='phonepe' />
                          </div>
                          <div className='img-upibox'>
                            <img src='images/paytm.svg' alt='paytm' />
                          </div>
                          <div className='img-upimore'>
                            <img src='images/plus.svg' alt='more' />
                          </div>
                          <div>
                            <h3 className='coin-value'>₹{values.amount}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </label>
              </div>

              {values.amount && (
                <div className='col-12 pay-btnbox mb-md-4'>
                  <button type='button' className='btn btn-pay w-100' disabled={!values.amount} onClick={() => (localStorage.getItem('authToken') ? handleSubmit() : navigate('/send-otp'))}>
                    Proceed to pay ₹{values.amount}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
